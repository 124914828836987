<template>
  <div>
    <core-page-title />

    <v-container>
      <v-row>
        <v-col>
          <btn-back to="/users" />
        </v-col>
      </v-row>

      <users-fields
        :loading-save="loading"
        :form-validation="formValidation"
        @save="save"
      />
    </v-container>
  </div>
</template>

<script>
  import usersApi from '@/api/users'

  export default {

    components: {
      UsersFields: () => import('./UsersFields'),
    },

    data () {
      return {
        loading: false,
        formValidation: {},
      }
    },

    methods: {
      async save (user) {
        this.loading = true
        this.formValidation = {}

        try {
          await usersApi.insert(user)

          this.$router.push('/users', () => this.$snackbar.show({ color: 'success', message: this.$messages._('new_success') }))
        } catch (e) {
          if (e.response.status === 422) {
            this.formValidation = this.$apiError.fieldsValidation(e)
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
          return
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>
